@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind utilities;

$primary-font-family: 'Inter', sans-serif;

body {
	font-family: $primary-font-family;
	padding: 0;
	margin: 0;
	background: #fafafa 0% 0% no-repeat;
	min-height: 100vh;
}

/* Chrome, Safari */
::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #dcdcdc;
	border-radius: 6px;
}

::-webkit-scrollbar-track {
	background-color: #ffffff00;
}

/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #dcdcdc #ffffff00;
}

.ant-typography {
	font-family: $primary-font-family;
}

.ant-menu-submenu-title > .ant-menu-title-content,
.ant-menu-item > .ant-menu-title-content {
	margin-inline-start: 10px;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	margin-inline-end: 4px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
	border-inline-end: none;
}

.ant-divider-horizontal.ant-divider-with-text {
	margin: 48px 0 24px 0;
}

.ant-table-thead {
	text-transform: uppercase;
	font-size: 12px;
}

.ant-table-container {
	border-radius: 15px;
}

.ant-table-tbody tr:last-child td:first-child {
	border-radius: 0 0 0 12px;
}

.ant-table-tbody tr:last-child td:last-child {
	border-radius: 0 0 12px 0;
}

.ant-table-content::-webkit-scrollbar {
	height: 8px;
}

.ant-table-placeholder .ant-table-cell {
	border-radius: 0 0 12px 12px !important;
}

.ant-table-expanded-row-fixed::after {
	border-inline-end: none !important;
}

.ant-pagination .ant-pagination-total-text {
	flex: 1;
}

.ant-collapse-content {
	overflow: hidden;
}

.jodit-container:not(.jodit_inline) {
	overflow: hidden;
	border-radius: 12px;
}
